import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';

export function useStorage () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  async function createFile ({ dataURI, owner, filenamePrefix = 'file' }) {
    if (!owner) {
      const activeFacility = await getActiveFacility();
      const facilityId = activeFacility?.id;
      if (!facilityId) return;

      owner = facilityId;
    }

    const payload = {
      owner,
      contentDataUri: dataURI,
      filename: `${filenamePrefix}-${Date.now()}`,
    };

    const file = await sdk.create('storage/files', payload);
    return file;
  }

  async function createLink (fileId) {
    const payload = {
      file: fileId,
    };

    const link = await sdk.create('storage/links', payload);
    return link;
  }

  async function deleteLink (linkId) {
    return await sdk.delete(`storage/links/${linkId}`);
  }

  async function deleteFile (fileId) {
    return await sdk.delete(`storage/files/${fileId}`);
  }

  async function locateFile (fileUrl) {
    if (!fileUrl) return;
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    return await sdk.get('storage/files', {
      owner: facilityId,
      url: new URL(fileUrl),
    });
  }

  async function upload ({ dataURI, owner, filenamePrefix = 'file' }) {
    const file = await createFile({ dataURI, owner, filenamePrefix });
    const link = await createLink(file?.id);
    return link;
  }

  async function deletePicURL (linkId, fileId) {
    try {
      await deleteLink(linkId);
      await deleteFile(fileId);
      return true;
    } catch (e) {
      console.log(e.message);
    }
  }

  return {
    upload,
    locateFile,
    deletePicURL,
  };
};
